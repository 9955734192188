import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import BackgroundVideo from "./js/BackgroundVideo";
import { gsap } from "gsap/all";
import "./app.scss";
import Alpine from "alpinejs";
import lazySizes from "lazysizes";
import Animations from "./js/Animations";
import Slider from "./js/Slider";
import Gallery from "./js/Gallery";
import Menu from "./js/Menu";
import VideoPopup from "./js/VideoPopup";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

Alpine.start();

let actions = {};
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

barba.hooks.beforeEnter((data) => {});

barba.hooks.after((data) => {
  runActions();
});
registerAction(Animations);
runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  console.log("runActions");
  Animations();
  action("Slider", Slider);
  action("Gallery", Gallery);
  action("BackgroundVideo", BackgroundVideo);
  Menu(document.querySelectorAll(".menu-btn"));
  VideoPopup(document.querySelectorAll(".video-btn"));

  // const div1 = document.querySelector(".head-one");
  // const div2 = document.querySelector(".head-two");

  // setInterval(() => {
  //   div1.classList.toggle("active");
  //   div2.classList.toggle("active");
  // }, 5000 /** 5 seconds */);

  tippy(".bike-build-btn", {
    content: "Bike Builds",
    placement: "right",
  });
  tippy(".calendar-btn", {
    content: "Book a service",
    placement: "left",
  });
  tippy(".bikefit-btn", {
    content: "Book a fitting",
    placement: "left",
  });
}

(() => {
  let mounted = false;
  let service = {
    _open: false,
    overlay: document.querySelector(".overlay"),
    open() {
      this._open = true;

      gsap.to(this.overlay, 0.3, {
        opacity: 1,
      });
      document.body.classList.add("overflow-hidden");
    },
    close() {
      this._open = false;
      document.body.classList.remove("overflow-hidden");
      gsap.to(this.overlay, 0.3, {
        opacity: 0,
        onComplete: () => {
          this.overlay.innerHTML = "";
        },
      });
    },
  };

  window.addEventListener("click", function (evt) {
    if (evt.target.classList.contains("close-modal")) {
      service.close();
    }
  });
})();
