import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { black } from "tailwindcss/colors";

gsap.registerPlugin(ScrollTrigger);

export default function Animations() {
  gsap.registerPlugin(ScrollTrigger);

  function setScrollImages() {
    gsap.to(".image-gallery", {
      scrollTrigger: {
        trigger: ".image-gallery-wall",
        pin: ".image-gallery-wall",
        toggleActions: "play reverse play reverse",
        scrub: true,
        // end: "+=3000s",
      },
      opacity: 1,
      scale: 0.4,
    });
  }
  setScrollImages();

  let tls = [];

  let tl2 = gsap
    .timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: ".marquee-section",
        // pin: true, // pin the trigger element while active
        start: "top bottom", // when the top of the trigger hits the top of the viewport
        end: "200%", // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
    })
    .to(
      ".marquee",
      {
        x: "10%",
      },
      "one"
    );

  tls.push(tl2);

  document.querySelectorAll(".fade-cont").forEach((blockImage) => {
    let ltl = gsap
      .timeline({
        scrollTrigger: {
          trigger: blockImage,
          start: "top center",
          markers: false,
          scrub: false,
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        blockImage,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );

    tls.push(ltl);
  });
}
